.no-js
{
  @include breakpoint(small only)
  {
    .top-bar
    {
      display: none;
    }
  }

  @include breakpoint(medium)
  {
    .title-bar
    {
      display: none;
    }
  }
}

/* Small only */
@media screen and (max-width: 39.9375em)
{
  body
  {
    font-size: 1rem;
  }
  header
  {
    .menu
    {
      a
      {
        border: none;
        padding: 0.4rem 0;
      }
    }
  }
  article
  {
    h1
    {
      font-size: 1.65rem;
    }

    h2
    {
      font-size: 1.3rem;
    }

    h3
    {
      font-size: 1.2rem;
    }

    h3
    {
      font-size: 1.1rem;
    }
  }

  .title-bar-left
  {
    flex-grow: 2;
  }

  .top-bar .menu-text
  {
    display: none;
  }

  p img
  {
    height: auto !important;
  }
}

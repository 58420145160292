body
{
  background-color: rgb(250, 240, 173);
}
header
{
  background-color: $header-background-color;
  color: $white;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0,0,0,.3);

  .logo
  {
    display: inline-block;
    margin-right: 0.4rem;

    img
    {
      max-height: 40px;
    }
  }

  .menu
  {
    .menu-text
    {
      padding: 0 1rem;
    }

    a
    {
      color: $white;
      padding: 1rem;
      border: solid get-color('primary');
      border-width: 0 0 5px;
      margin: 0 .25rem;
      font-size: 1.1rem;
      letter-spacing: 0.05rem;
    }

    a:hover
    {
      border-bottom-color: get-color('secondary');
    }

    .is-active > a
    {
      background-color: transparent;
      border-bottom-color: get-color('secondary');
    }
  }

  .top-bar
  {
    padding: 0 0.5rem;
  }

  .title-bar
  {
    background-color: get-color('primary');
    z-index: 9;
  }
}

.FEH-contentWrap
{
  background-color: rgba(243,243,243,.8);
  padding: 3rem 0 2rem;
}
.FEH-content
{
  margin: auto;
  max-width: 960px;
}

.FEH-footWrap
{

  .FEH-content
  {
    padding: 40px 0;
  }

  .FEH-address
  {
    background-color: rgba(230,230,230,.8);
  }

  .FEH-footNav
  {
    background-color: rgba(218, 218, 218, 0.8);
  }

  .FEH-footCopy p
  {
    margin: 0;
    padding: .7rem 1rem;
    line-height: 1;
  }
}

.menu
{

}

.FEH-navFooter.menu
{
  a {
    color: get-color('primary');
    padding: .7rem .5rem;
    background-color: transparent;
  }
}

article
{
  h1 {
    font-family: 'Exo 2', sans-serif;
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }
  h2
  {
    font-family: 'Exo 2', sans-serif;
    font-size: 1.6rem;
  }
  h3
  {
    font-family: 'Exo 2', sans-serif;
    font-size: 1.3rem;
  }
}

.FEH-gallery
{
  max-width: 800px;

  .crop.thumbnail
  {
    width: 180px;
    height: 180px;
    overflow: hidden;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .crop.thumbnail.landscape img
  {
    height: 180px;
    width: auto;
    max-width: none;
  }
  .crop.thumbnail.portrait img
  {
    width: 180px;
    height: auto;
  }

}

.teaser
{
  img
  {

  }

  a.button
  {
    margin-top: .5rem;
    width: 100%;
    font-size: 1.1rem;
  }
}